import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom';
 
const apiUrl = process.env.REACT_APP_API_URL;
const appName = process.env.REACT_APP_NAME;

console.log("apiUrl:"+apiUrl);
const Dashboard = ({ userId, userEmail }) => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
 
    useEffect(() => {
        document.title = appName;
        refreshToken();
    }, []);

    console.log("userId",userId);
    console.log("userEmail",userEmail);

    const refreshToken = async () => {
        try {
            const response = await axios.get(apiUrl+'/token');
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
            if (error.response) {
                // history.push("/");
                navigate("/login");
            }
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(apiUrl+'/token');
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwtDecode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

 
    return (
        <div className="container mt-5">
        <p>Welcome Back: {name}</p>
        </div>
    )
}
 
export default Dashboard