import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL; 
const appName = process.env.REACT_APP_NAME;

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const navigate = useNavigate();
 
    useEffect(() => {
    document.title = appName;
    }, []);

    const Auth = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/login`, {
                email: email,
                password: password,
            });

            console.log("response userId",response.data.userId);
            console.log("response email",response.data.email);

            // Extract user details from the API response
            // const { userId, email: userEmail } = response.data;

            // Store the user details in localStorage (or sessionStorage if preferred)
            localStorage.setItem("userId", response.data.userId);
            localStorage.setItem("userEmail", response.data.email);

            console.log("Saved userId:", localStorage.getItem("userId"));
            console.log("Saved userEmail:", localStorage.getItem("userEmail"));

            // navigate.push("/dashboard");
            navigate("/dashboard");
        } catch (error) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }
 
    
    return (
        <section className="hero has-background-grey-light is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4-desktop">
                            <form onSubmit={Auth} className="box">
                                <p className="has-text-centered">{msg}</p>
                                <div className="field mt-5">
                                    <label className="label">Email</label>
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Username" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Password</label>
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="******" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <button className="button is-success is-fullwidth">Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
    
}
 
export default Login