import React, { useState } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const appName = process.env.REACT_APP_NAME;

const ResetPassword = () => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confPassword, setConfPassword] = useState("");
    const [message, setMessage] = useState("");

    const userId = localStorage.getItem("userId");
    const userEmail = localStorage.getItem("userEmail");


    // console.log("userId:", userId);
    // console.log("userEmail:", userEmail);
    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confPassword) {
            setMessage("Passwords do not match");
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/api/reset-password`, {
                userId, // Send the saved userId
                userEmail,  // Send the saved email
                newPassword
            });
            setMessage(response.data.msg);
        } catch (error) {
            if (error.response) {
                setMessage(error.response.data.msg);
            }
        }
    };




 
    return (
        <section className="hero has-background-black is-fullheight is-fullwidth">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4-desktop">
                            <p className="title is-3">Reset Password</p>
                            <form onSubmit={handleResetPassword} className="box">
                                <p className="has-text-centered">{message}</p>
                                <div className="field mt-5">
                                    <label className="label">Current Password</label>
                                    <div className="controls">
                                        <input
                                        className="input"
                                        type="password"
                                        placeholder="Enter current password"
                                        id="currentPassword"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        required
                                          />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">New Password</label>
                                    <div className="controls">
                                        <input
                                        className="input"
                                        type="password"
                                        placeholder="Enter new password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                        
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    <label className="label">Confirm New Password</label>
                                    <div className="controls">
                                        <input
                                        className="input"
                                        type="password"
                                        placeholder="Enter new password"
                                        value={confPassword}
                                        onChange={(e) => setConfPassword(e.target.value)}
                                        />
                                        
                                    </div>
                                </div>

                                <div className="field mt-5">
                                    <button className="button is-success is-fullwidth">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )





};

export default ResetPassword;